<template>
  <div class="hello">
    <div class="head">
      <div class="text">北京数太科技有限公司</div>
      <div>
        <span 
          @click="changeBtn(item.value)"
          class="tab-button"
          :class="{ active: tabsActive == item.value }"
          v-for="(item,index) in menu" 
          :key="index"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="carousel" id="carousel">
      <el-carousel trigger="click" :interval="interval">
        <el-carousel-item v-for="(item,index) in carouselList" :key="index" sttyle="position:relative;">
          <div class="ingtext">
            <div class="title1">{{ item.title1 }}</div>
            <div class="title2">{{ item.title2 }}</div>
            <div class="title3">{{ item.title3 }}</div>
            <div class="buttonText" @click="skip('contact')">{{ item.buttonText }}</div>
          </div>
          <img :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="aboutus" id="aboutus">
      <div class="title">数太科技</div>
      <div class="title" style="margin-bottom:60px;">打造互联网数据与应用生态体系</div>
      <div class="text">数据流动、数据分析与数据存储将不可阻挡的形成跨域趋势，数太科技致力于提供互联网与大数据产业从“互联网+”到““互联网+”的转型的数据与数据应用平台。这将是一个可扩展、低延迟、 高交互、并拥有可扩安全链域护栏的分布式网络平台。</div>
      <div class="text">数太科技以数字孪生技术为基础，在实现对各行各业基础数据采集，整理的过程中自建数据模型、数据引擎与引索机制为用户提供更为直接美观的可视化平台、提供专项维度数据流转服务与数据分析应用体系，同时为用户提供一站式的去中心化数据应用与互联网服务的平台级解决方案</div>
    </div>
    <div class="network" id="network">
      <div style="margin-bottom:100px;animation: 0s ease 0s 1 normal none running none;">
        <div style="font-size:30px;font-weight:600;margin-bottom:20px;">数太科技</div>
        <div>EOT数据生态愿景：互联网数据(数据银行)、EOT 数据模型与引擎、数据可视化与数据应用平台EOT Network</div>
      </div>
      <div class="example">
        <div v-for="(item,index) in exampleList" :key="index">
          <img :src="item.url" alt="">
          <p class="title">{{ item.title }}</p>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="management" id="management">
      <div style="margin-bottom: 140px;text-align:center">
        <div style="font-size:30px;font-weight:600;margin-bottom:10px;color:#02b2b5">Management Mode</div>
        <div>我们将作为使用数据系统、数据应用服务、数据分析或数据市场流通的模式</div>
      </div>
      <div style="display:flex;justify-content: space-between;">
        <div>
          <div style="font-size:16px;color:#666;margin-bottom:60px">企业管理模式及含义</div>
          <div style="width:500px;line-height:30px;margin-bottom:60px">科学的管理模式就是对称管理模式
            主体与客体相对称、主体性与科学性相统一的管理模式。
            中华传统文化的特色是对称文化。对称文化：天与人、义与利、道与德、理与气、名与实、形上与形下、德治与法治、出世与入世的对称。对称文化：民主与法制的对称、公平与效率的对称、人的理性与非理性的对称、个人与企业的对称、民间与政府的对称。中国管理模式就是对称管理模式。</div>
          <div class="bot-button" @click="skip('contact')">
            联系我们
          </div>
        </div>
        <div>
          <img src="@/assets/management.png" alt="">
        </div>
      </div>
    </div>
    <!-- <div class="create" id="create">
      <div style="text-align:center">
        <div style="font-size:30px;font-weight:600;margin-bottom:10px;color:#02b2b5">数太科技 创业团队</div>
        <div>数据生态愿景：互联网数据(数据银行)、 数据模型与引擎、数据可视化与数据应用平台</div>
      </div>
      <div class="createExample">
        <div v-for="(item,index) in createList" :key="index">
          <img :src="item.url" alt="">
          <p class="title">{{ item.title }}</p>
          <p class="title2">{{ item.title2 }}</p>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div> -->
    <div class="develop aboutus" id="develop">
      <div class="title" style="margin-bottom:60px;">数太科技 发展历程</div>
      <div class="text">数据生态愿景：互联网数据(数据银行)、 数据模型与引擎、数据可视化与数据应用平台。</div>
      <div class="text">数太科技将自建数据模型、数据引擎与引索机制为生态用户提供可视化、便捷的自设更细颗粒度的分析、提供专项维度数据流转服务与数据分析应用体系，同时为用户提供一站式的去中心化数据应用与互联网服务的平台级解决方案。</div>
      <div class="chart">
        <img src="@/assets/chart.jpg" alt="">
      </div>
    </div>
    <div class="contact" id="contact">
      <div class="icp">京ICP备12002787号-5</div>
      <div class="left">
        <div>
          <img src="@/assets/logo.png" alt="">
          <!-- <span>数太科技</span> -->
        </div>
        <div>
          <p>联系电话：020-000000000</p>
          <p>联系QQ：17748823654</p>
          <p>手机号码：12535412562</p>
          <p>联系邮箱：215822533@co.m</p>
        </div>
      </div>
      <div class="right">
        <el-form ref="form" :model="form">
          <el-form-item label="您的姓名">
            <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.phone" placeholder="请留下您的联系方式"></el-input>
          </el-form-item>
          <el-form-item label="联系地址">
            <el-input v-model="form.address" placeholder="请输入您的联系地址"></el-input>
          </el-form-item>
          <el-form-item label="合作需求" style="width:100%">
            <el-input type="textarea" v-model="form.cooperation" placeholder="我能为您做什么"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  components: {},
  computed: {},
  props: {},
  created() {},
  data() {
    return {
      form: {
        name: '',
        phone: '',
        address: '',
        cooperation: '',
      },
      exampleList:[
        {
          url:require('@/assets/network1.png'),
          title:'大数据中心',
          content:'全域、跨链的共享数据流，任何人与数据源的相关数据都可以贡献和流转。'
        },
        {
          url:require('@/assets/network2.png'),
          title:'分布式存储',
          content:'高性能的数据处理和分析引擎以分布式的方式执行存储，提供可视化便捷的自设分析。'
        },
        {
          url:require('@/assets/network3.png'),
          title:'数据应用平台',
          content:'数据应用平台构成一个可用性层和工具箱，使数据上链与大数据互联网转型快速发展。'
        },
        {
          url:require('@/assets/network4.png'),
          title:'大数据中心',
          content:'数太科技生态架构以链接不同来源的元数据为核心，打造数据模型服务多种业态。'
        },
      ],
      createList:[
        {
          url:require('@/assets/network1.png'),
          title:'Miss.Huang',
          title2:'联合创始人',
          content:'公开大学毕业、商科学士，资深数据及互联网专家，具有着丰富的数据与互联网经验'
        },
        {
          url:require('@/assets/network1.png'),
          title:'Miss.Huang',
          title2:'联合创始人',
          content:'公开大学毕业、商科学士，资深数据及互联网专家，具有着丰富的数据与互联网经验'
        },
        {
          url:require('@/assets/network1.png'),
          title:'Miss.Huang',
          title2:'联合创始人',
          content:'公开大学毕业、商科学士，资深数据及互联网专家，具有着丰富的数据与互联网经验'
        },
        {
          url:require('@/assets/network1.png'),
          title:'Miss.Huang',
          title2:'联合创始人',
          content:'公开大学毕业、商科学士，资深数据及互联网专家，具有着丰富的数据与互联网经验'
        },
      ],
      interval: 5000,
      tabsActive: 'carousel',
      menu:[
        {
          name: '首页',
          value: 'carousel',
        },
        {
          name: '关于我们',
          value: 'aboutus',
        },
        {
          name: '服务案例',
          value: 'network',
        },
        {
          name: '管理模式',
          value: 'management',
        },
        {
          name: '发展历程',
          value: 'develop',
        },
        // {
        //   name: '创业团队',
        //   value: 'create',
        // },
      ],
      carouselList: [
        {
          url: require('@/assets/carousel1.png'),
          title1: '软件服务',
          title2: 'SOFTWARE SERVICES',
          title3: '基础软件服务、应用软件服务、软件开发、软件咨询及提供成熟的软件产品',
          buttonText: 'contact'
        },
        {
          url: require('@/assets/carousel2.png'),
          title1: '数据监测管理',
          title2: 'BLOCK CHAIN TEST MANAGEMENT SYSTEM',
          title3: '数据流动、数据分析与数据存储将不可阻挡的形成跨域趋势TEMPLATE致力于打造基于EOS互联网3.0数据与应用',
          buttonText: '联系我们'
        },
        {
          url: require('@/assets/carousel1.png'),
          title1: '软件服务',
          title2: 'SOFTWARE SERVICES',
          title3: '基础软件服务、应用软件服务、软件开发、软件咨询及提供成熟的软件产品',
          buttonText: 'contact'
        },
        {
          url: require('@/assets/carousel2.png'),
          title1: '数据监测管理',
          title2: 'BLOCK CHAIN TEST MANAGEMENT SYSTEM',
          title3: '数据流动、数据分析与数据存储将不可阻挡的形成跨域趋势TEMPLATE致力于打造基于EOS互联网3.0数据与应用',
          buttonText: '联系我们'
        },
      ]
    }
  },
  methods: {
    skip(data){
      document.querySelector('#' + data).scrollIntoView({
        behavior:'smooth'
      })
    },
    changeBtn(value) {
      this.tabsActive = value
      this.skip(value)
    },
    onSubmit() {
        console.log('submit!');
      }
  }
}
</script>
<style scoped>
/* @keyframes mymove
{
	from {left:0px;}
	to {left:200px;}
}
@-webkit-keyframes mymove
{
	from {left:0px;}
	to {left:200px;}
} */
.hello{
  width:100%;
  height:100%;
}
.head{
  width:60%;
  position: absolute;
  top:0;
  left:20%;
  z-index: 999;
  color:rgba(222, 222, 222, 1);
  display:flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding:25px 0
}
.head .text{
  height:30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: 600;
  color:#02b2b5;
}
.tab-button{
  cursor: pointer;
  display:inline-block;
  height:30px;
  line-height: 30px;
  padding:0 12px;
}
.tab-button.active{
  color: rgb(2, 178, 181);
}
.carousel{
  width:100%;
  height:800px;
}
.el-carousel{
  width:100%;
  height:100%;
}
.carousel >>> .el-carousel__container{
  width:100%;
  height:100%;
}
.carousel >>> .el-carousel__indicators{
  display:none;
}
.carousel >>> .el-carousel__item.is-animating{
  transition: transform 1.5s ease-in-out;
}
.carousel >>> .el-carousel__arrow{
  font-size: 50px;
}
.carousel img{
  width:100%;
  height:100%;
}
.ingtext{
  position:absolute;
  top:34%;
  left:20%;
  color: rgb(48, 136, 163);
}
.title1{
  font-size: 38px;
  font-weight: 600;
  color: rgb(125, 212, 238);
  margin-top:5px;
}
.title2{
  font-size: 18px;
}
.title3{
  font-size: 12px;
  margin-top:20px;
  width:340px;
  line-height: 24px;
}
.buttonText{
  cursor: pointer;
  color:rgb(185, 180, 180);
  width:140px;
  border-radius: 20px;
  text-align: center;
  height:34px;
  line-height: 34px;
  margin-top:25px;
  border: 1px solid rgb(125, 212, 238);
}
.aboutus,.management,.network,.create,.develop{
  width:100%;
  height:960px;
  box-sizing: border-box;
}
.aboutus{
  padding: 8% 0 0 20%;
  background: url('../assets/aboutus.png') no-repeat;
  background-size: 100% 100%;
}
.aboutus .title{
  font-size: 30px;
  color: rgb(2, 178, 181);
  font-weight: 600;
}
.aboutus .text{
  width:480px;
  font-size: 13px;
  color: #999;
  font-weight: 400;
  line-height: 26px;
  margin-bottom:30px;
}
.network{
  padding: 10% 30px;
  background: url('../assets/network.png') no-repeat;
  background-size: 100% 100%;
  color:#fff;
  text-align: center;
}
.example{
  width:60%;
  margin:0 auto;
  display:flex;
  justify-content: space-between;
  /* animation:mymove 5s infinite;
	-webkit-animation:mymove 5s infinite; */
}
.example div{
  width:24%;
  height:450px;
  text-align: center;
  background: #fff;
  box-sizing: border-box;
  padding:50px;
}
.example p{
  line-height: 25px;
  font-size: 13px;
  color:#999;
}
.example .title{
  margin-top:80px;
  height:40px;
  line-height: 40px;
  color:#000;
  font-weight: 600;
  font-size: 16px;
}
.management{
  padding: 10% 18%;
  color:#999;
}
.bot-button{
  cursor: pointer;
  width:140px;
  height:34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  background: url('../assets/button-back.png') no-repeat;
  background-size: 100% 100%;
}
.create{
  padding: 10% 30px;
  background: url('../assets/create.png') no-repeat;
  background-size: 100% 100%;
}
.createExample{
  width:70%;
  margin:40px auto 0;
  display:flex;
  justify-content: space-between;
}
.createExample div{
  padding:60px;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  color:#999;
}
.createExample .title{
  font-size: 16px;
  color:#000;
  margin:10px 0 0 0;
}
.createExample .title2{
  font-size: 12px;
  margin:0;
  color:#666;
}
.createExample img{
  display: inline-block;
  width:140px;
  height:140px;
  border-radius: 50%;
}
.develop.aboutus{
  background: url('../assets/develop.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.develop .chart{
  position: absolute;
  right:19%;
  bottom:12%;
}
.contact{
  width:100%;
  height:600px;
  padding:4% 20%;
  box-sizing: border-box;
  background: url('../assets/contactus.png') no-repeat;
  background-size: 100% 100%;
  display:flex;
  justify-content: space-between;
  position: relative;
}
.icp{
  color:#fff;
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  bottom:2%;
}
.left{
  height:100%;
  color:#fff;
}
.left div{
  height:50%;
}
.left img{
  width:150px;
  height:70px;
  display:block;
}
.left span{
  font-size: 13px;
  width:120px;
  display: block;
  text-align: center;
  color:#02b2b5;
}
.right{
  width:600px;
}
.right >>> .el-form{
  width:100%;
  display:flex;
  flex-wrap: wrap;
}
.right >>> .el-form-item{
  width:40%;
  margin-right:5%;
}
.right >>> .el-form-item__label{
  color:#fff;
}
.right >>> .el-form-item__content{
  margin-left:0;
}
.right >>> .el-textarea__inner{
  min-height: 120px !important;
}
.el-button{
  width:100%;
}
</style>
